import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import * as Yup from "yup";
import {Modal} from "react-bootstrap";
import {Formik} from "formik";
import lock from "../../assets/image/lock-closed.svg";
import {Link} from "react-router-dom";
import Swal from "sweetalert2";
import {useChangePassMutation, useChangePw2Mutation} from "../../redux/services/auth";
import {useDispatch} from "react-redux";
import {updateUserPinStat} from "../../redux/slices/authSlice";


const changePw2Schema = Yup.object().shape({
    pw2: Yup.string().min(6, 'Mật khẩu quá ngắn, tối thiểu 6 ký tự').required('Vui lòng nhập mật khẩu')
});
const ChangePw2 = ({content, openModal, onClose}) => {

    const [show, setShow] = useState(false);
    const [isErrChangePass, setErrChangePass] = useState(null);
    const dispatch = useDispatch();

    const [changePassRq, {isChanging}] = useChangePw2Mutation();

    const handleClose = () => {
        setShow(false);
        onClose(false);
    };

    const handleSubmit = async (values, actions) => {
        const {pw2} = values;

        try {
            const response = await changePassRq({pw2}).unwrap();
            if (response) {
                Swal.fire({
                    icon: 'success',
                    title: 'Đổi mật khẩu thành công !',
                });
                dispatch(updateUserPinStat());
                handleClose();
            }
        } catch (err) {
            setErrChangePass(err.data?.message || err.data?.error || 'Có lỗi xảy ra');
        }
    }
    const handleShow = () => setShow(true);

    useEffect(() => {
        setShow(openModal);
    }, [openModal]);

    return (
        <Modal
            show={show}
            size="lg"
            onHide={handleClose}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            // backdrop={'static'}
        >
            <Modal.Header closeButton>Đổi mật khẩu 2</Modal.Header>
            <Modal.Body className={'p-0'}>
                <div className="row popup-content">
                    <div className={'col-12'}>
                        {isErrChangePass && <p className={'text-danger p-3'}>{isErrChangePass}</p>}
                        <Formik
                            initialValues={{pw2: '',}}
                            onSubmit={handleSubmit}
                            validateOnBlur={false}
                            validateOnChange={false}
                            validationSchema={changePw2Schema}
                        >
                            {props => (
                                <form onSubmit={props.handleSubmit} className={'d-flex flex-column p-3'} style={{gap: '8px'}}>
                                    <div className="form-group d-flex flex-column" style={{gap: '4px'}}>
                                        <label htmlFor="exampleInputEmail1">Mật khẩu 2</label>
                                        <input id={"exampleInputEmail1"} className="form-control has-icon" name={'pw2'} onChange={props.handleChange} value={props.values.pw2} type="password" placeholder={'Mật khẩu 2'}/>
                                        {props.errors.pw2 && <div className="form-text text-danger">{props.errors.pw2}</div>}
                                    </div>
                                    <button type="submit" className="btn btn-primary">Đổi mật khẩu 2</button>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

ChangePw2.propTypes = {};

export default ChangePw2;