import {useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import starImg from '../../assets/image/star.svg';
import {Formik} from "formik";
import {getProviderName, numberWithCommas} from "../../utils/utils";
import * as Yup from "yup";
import {useRef, useState} from "react";
import Swal from "sweetalert2";
import {useTopupCardMutation} from "../../redux/services/common";
import {useAuth} from "../../utils/hooks/useAuth";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";


const cardSchema = Yup.object().shape({
    provider: Yup.string().required('Vui lòng chọn loại thẻ'),
    price: Yup.string().required('Vui lòng chọn mệnh giá'),
    code: Yup.string().required('Vui lòng nhập mã code'),
    serial: Yup.string().required('Vui lòng nhập mã serial'),
});

const Topup = () => {
    const auth = useAuth();
    const gRecaptchaSiteKey = useSelector(state => state.app.gRecaptchaSiteKey);
    const page = useSelector(state => state.app.popups.find(item => item.PageCode === 'ATM'));
    const cardList = useSelector(state => state.app.cards);
    const bankID = useSelector(state => state.app.settings.bankID);
    const bankCode = useSelector(state => state.app.settings.bankCode);
    const accountName = useSelector(state => state.app.settings.accountName);
    const [cardPriceList, setCardPriceList] = useState([]);
    const [topupReq, {isLoading}] = useTopupCardMutation();
    const navigate = useNavigate();
    const refReset = useRef();
    let qrCodeURL = "";
    if (auth.user && bankID && bankCode && accountName) {
        qrCodeURL = `https://img.vietqr.io/image/${bankCode}-${bankID}-print.png?&addInfo=${encodeURI(auth.user.UserName)}&accountName=${accountName}`;
    }


    const onChooseCardType = (e, props) => {
        if (e.target.value) {
            const priceList = cardList.find(p => p.providerCode === e.target.value);
            setCardPriceList(priceList.priceList);
        }
        props.handleChange(e);
    }

    const handleSubmit = async (values, actions) => {

        if (!auth.user) {
            Swal.fire({
                icon: 'error',
                title: 'Vui lòng đăng nhập để có thể nạp tiền',
                confirmButtonText: 'OK',
            }).then((r) => {
                if (r.isConfirmed) {
                    navigate('/login');
                }
            });
            return;
        }
        const {provider, price, code, serial} = values;
        window.grecaptcha.ready(async () => {
            try {
                const token = await window.grecaptcha.execute(gRecaptchaSiteKey, {action: 'submit'});
                if (token) {
                    const response = await topupReq({provider, price, code, serial, gCaptchaToken: token}).unwrap();
                    if (response) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Giao dịch của bạn đã được hệ thống ghi nhận và xử lí, chúng tôi sẽ cập nhật tài khoản của bạn sau khi giao dịch thành công',
                            confirmButtonText: 'OK',
                        });
                        invokeReset();
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Verify captcha thất bại',
                        confirmButtonText: 'OK',
                    })
                }
            } catch (err) {
                Swal.fire({
                    icon: 'error',
                    title: err.data?.message || err.data?.error || 'Có lỗi xảy ra',
                    confirmButtonText: 'OK',
                });
            }
        });
    }

    const invokeReset = () => {
        if (refReset.current) {
            refReset.current.click();
        }
    }
    return (
        <>
            <div className="row page-header mb-2">
                <div className="col-12 page-title">
                    <h4>Nạp thẻ</h4>
                </div>
            </div>

            {auth.user && qrCodeURL ?
                <div className="d-flex justify-content-center">
                    <img src={qrCodeURL} loading="lazy" style={{width: "450px"}}/>
                </div>
                : null
            }

            <div className="row page-content">
                <div className="col-12">
                    <div dangerouslySetInnerHTML={{__html: page.Content}}></div>
                </div>
            </div>
        </>


    );

}
export default Topup