import {Link, useNavigate} from "react-router-dom";
import icon from "../../assets/image/user.svg";
import {useAuth} from "../../utils/hooks/useAuth";
import {unsetCredentials} from "../../redux/slices/authSlice";
import {useLogoutMutation} from "../../redux/services/auth";
import {useDispatch} from "react-redux";
import {numberWithCommas} from "../../utils/utils";

const LoginSection = () => {
    const auth = useAuth();
    const [logout, {isLogginOut}] = useLogoutMutation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const onLogout = async () => {
        if (window.confirm("Bạn muốn đăng xuất chứ ?")) {
            try {
                const res = await logout().unwrap();
                if (res) {
                    dispatch(unsetCredentials());
                    navigate("/");
                }
            } catch (err) {
                alert('Logout thất bại');
                console.error(err);
            }
        }
    }
    return (
        <div className="login-menu">
            {auth.user ?
                <div className={'dropdown'}>
                    <a className="login-text dropdown-toggle" href="#" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                        <img width="24px" height="24px" src={icon} alt="imgicon"/> {auth.user.UserName}
                    </a>
                    <ul className="dropdown-menu login-success" aria-labelledby="dropdownMenuButton2">
                        <li className="user-info">
                            <p className="dropdown-item"> ATM: <strong>{numberWithCommas(auth.user.credit_atm)} FGC </strong></p>
                            {auth.user.credit_atm_locked && auth.user.credit_atm_locked > 0 ?
                                <p className="dropdown-item"> Đang khóa: <strong>{numberWithCommas(auth.user.credit_atm_locked)} FGC </strong>
                                </p> : null}
                        </li>
                        <li>
                            <hr className="dropdown-divider"/>
                        </li>
                        <li style={{padding: '5px 0px'}}>
                            <Link to={'/user/transaction-history'} style={{color: '#000', textDecoration: 'none'}} title="Tài khoản đã mua" className="dropdown-item">Lịch sử giao dịch</Link>
                        </li>
                        <li style={{padding: '5px 0px'}}>
                            <Link to={'/user/topup-history'} style={{color: '#000', textDecoration: 'none'}} title="Lịch sử nạp tiền" className="dropdown-item">Lịch sử nạp tiền</Link>
                        </li>
                        <li style={{padding: '5px 0px'}}>
                            <Link to={'/change-pass'} style={{color: '#000', textDecoration: 'none'}} title="Đổi mật khẩu" className="dropdown-item">Đổi mật khẩu</Link>
                        </li>
                        <li>
                            <hr className="dropdown-divider"/>
                        </li>
                        <li style={{padding: '5px 0px'}}>
                            <a style={{color: '#000', textDecoration: 'none'}} className="dropdown-item cursor-pointer text-danger" onClick={onLogout} title="Đăng xuất">Đăng xuất</a>
                        </li>
                    </ul>
                </div>
                :
                <Link to={'/login'} className="login-text">
                    <img src={icon} alt="imgicon"/> Đăng nhập/ Đăng kí
                </Link>
            }
        </div>
    );
}
export default LoginSection;